
import React from 'react';

const Logo = () => {
	return (
		<svg width="98" height="51" viewBox="0 0 98 51">
			<defs>
				<clipPath id="clip-Plan_de_travail_1">
					<rect width="98" height="51" />
				</clipPath>
			</defs>
			<g id="Plan_de_travail_1" data-name="Plan de travail – 1" clipPath="url(#clip-Plan_de_travail_1)">
				<rect id="Rectangle_4" data-name="Rectangle 4" width="36" height="36" transform="translate(72.456) rotate(45)" fill="#2bb8d8" opacity="0.25" />
				<rect id="Rectangle_5" data-name="Rectangle 5" width="36" height="36" transform="translate(25.456) rotate(45)" fill="#2bb8d8" opacity="0.25" />
				<rect id="Rectangle_2" data-name="Rectangle 2" width="36" height="36" transform="translate(36.456) rotate(45)" fill="#2bb8d8" opacity="0.25" />
				<rect id="Rectangle_3" data-name="Rectangle 3" width="36" height="36" transform="translate(60.456) rotate(45)" fill="#2bb8d8" opacity="0.25" />
				<rect id="Rectangle_1" data-name="Rectangle 1" width="36" height="36" transform="translate(48.456) rotate(45)" fill="#2bb8d8" />
			</g>
		</svg>
	);
};

export default Logo;