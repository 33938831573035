import React from 'react';
import './contact-form.scss';

const ContactForm = ({lang}) => {
    const handleSumbit = e => {
        e.preventDefault()
    }
    const content = {
        fr: {
            name: 'Nom',
            email: 'Email',
            message: 'Message',
            send: 'Envoyer'
        },
        en: {
            name: 'Name',
            email: 'Email',
            message: 'Message',
            send: 'Send'
        }
    }
    return (
        lang &&
        <div className="contact-form">
            <form className="form" action="mail.php" id="contact-form" method="post">
                <input required name="name" type="text" placeholder={content[lang].name} />
                <input required name="email" type="email" placeholder={content[lang].email}/>
                <textarea required name="message" placeholder={content[lang].message}/>
                <button onClick={() => handleSumbit} name="submit">{content[lang].send}</button>
            </form>
        </div>
    );
};

export default ContactForm