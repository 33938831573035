import React from 'react';

const MailTo = ({children, href, subject, sx}) => {

    const c = {
        "a":'\x61',
        "b":'\x62',
        "c":'\x63',
        "d":'\x64',
        "e":'\x65',
        "f":'\x66',
        "g":'\x67',
        "h":'\x68',
        "i":'\x69',
        "j":'\x6A',
        "k":'\x6B',
        "l":'\x6C',
        "m":'\x6D',
        "n":'\x6E',
        "o":'\x6F',
        "p":'\x70',
        "q":'\x71',
        "r":'\x72',
        "s":'\x73',
        "t":'\x74',
        "u":'\x75',
        "v":'\x76',
        "w":'\x77',
        "x":'\x78',
        "y":'\x79',
        "z":'\x7A',
        "1":'\x31',
        "2":'\x32',
        "3":'\x33',
        "4":'\x34',
        "5":'\x35',
        "6":'\x36',
        "7":'\x37',
        "8":'\x38',
        "9":'\x39',
        "0":'\x30',
        "-":'\x2D',
        "_":'\x5F',
        ".":'\x2E',
        "@":'\x40'
    }

    const splitHref = href.split("");
    let UTFHref = '';

    for(let i = 0; i < splitHref.length; i ++){
        if(c[splitHref[i]]){
            UTFHref += c[splitHref[i]]
        }
    }

    let _0xe9ad=['\x68\x72\x65\x66','\x6C\x6F\x63\x61\x74\x69\x6F\x6E','\x6D\x61\x69\x6C\x74\x6F\x3A'];

	const emailme = e => {
        window[_0xe9ad[1]][_0xe9ad[0]]= _0xe9ad[2] + UTFHref + '?subject=' + subject;
    }

	return (
		<a onClick={(e) => emailme(e)} sx={sx} href="#">
            {children}
		</a>
	);

};

export default MailTo;
