import React from 'react';
import ContactBlock from '../components/contact/contactBlock';
import Logo from '../images/logo.js';
import './../styles/normalize.scss'
import './../styles/main.scss'
import './../styles/landing-page.scss'
import { Helmet } from 'react-helmet';

const IndexPage = () => {
	return (
		<div className="content-container" style={{ margin: 0 }}>
			<Helmet
				htmlAttributes={{lang: "en"}}
				title="Home | DE-FOUNDATION"
				meta={[{name: "description", content: 'DE-Foundation Home Page'}]}
			/>
			<header>
				<Logo/>
			</header>
			<main>
				<div className='soon'>
					<h1>DE-FOUNDATION</h1>
					<h2>is coming back soon.</h2>
					<p> Stay tuned !</p>
				</div>
			</main>
			<footer>
				<ContactBlock
					lang={'en'}
				/>
			</footer>
		</div>
	);
};

export default IndexPage;
