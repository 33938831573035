import React from 'react';
import { GetContactPageEN } from '../../content/pages/contact/content/en/GetContactPage';
import MailTo from '../utils/mailTo';
import ContactForm from './contactForm';

const ContactBlock = ({lang}) => {
    const content = GetContactPageEN()
    return (
        <div>
            <p className='contact-text'> {content.contactContent}</p>
                <div className='contact'>
                <ContactForm
                        lang={'en'}
                    />            
                    
                <div className='address'>
                {
                    content.contactAdress.map((e,i)=> (
                        <p key={i}>{e}</p>
                    ))
                }
                    <MailTo subject="Contact - DE-Foundation" href="direction@bochslerfinance.com" >{content.contactEmail}</MailTo>
                </div>
            </div>

        </div>
    );
};

export default ContactBlock;